// -------------------------------------------------------
// Visually hide content
// -------------------------------------------------------

@mixin hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
}

// -------------------------------------------------------
// Reseting list menus
// -------------------------------------------------------

@mixin list-reset() {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin: 0;
        padding: 0;
    }
}

// -------------------------------------------------------
// Setting a flexible ratio
// -------------------------------------------------------

@mixin ratio($ratio) {
    &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: $ratio;
    }
}

// -------------------------------------------------------
// hover helper
// -------------------------------------------------------

@mixin hover() {
    html:not(.mobile):not(.tablet) &:hover,
    html:not(.mobile):not(.tablet) &:focus,
    &.on-press {
        @content;
    }
}

// -------------------------------------------------------
// flex box grid
// -------------------------------------------------------

@mixin flex-grid($columnCount, $gutterSize: 0px, $bottomMargin: 0px, $selector: ".col") {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    @if $gutterSize != "0px" or $gutterSize != "0" or $gutterSize != 0px or $gutterSize != 0 {
        margin-left: -#{$gutterSize};
    }

    #{$selector} {
        padding-left: #{$gutterSize};
        width: (100 / $columnCount) * 1%;
        @if $bottomMargin != "0px" or $bottomMargin != 0px {
            margin-bottom: $bottomMargin;
        }
    }

    @if $columnCount == 1 {
        margin-left: 0;

        #{$selector} {
            padding-left: 0;
        }
    }
}

// -------------------------------------------------------
// breakpoints
// -------------------------------------------------------

@mixin breakpoint($min: 0, $max: 0, $side: "width") {
    // check to see if first variable is string or number
    $type: type-of($min);
    $sideType: type-of($side);

    // if it's a number, check if min, max or both are required.
    // then check if the values of $side are correctly spelt 'width' or 'height'
    // write new breakpoint using these boundaries.
    // default to 'all' if nothing specified.
    @if $type == number and $sideType == string {
        @if $side == "width" or $side == "height" {
            $query: "all" !default;
            @if $min != 0 and $max != 0 {
                $query: "(min-#{$side}: #{$min}) and (max-#{$side}: #{$max})";
            } // set both min and max
            @else if $min != 0 and $max == 0 {
                $query: "(min-#{$side}: #{$min})";
            } // set just min
            @else if $min == 0 and $max != 0 {
                $query: "(max-#{$side}: #{$max})";
            } // set just max
            @media #{$query} {
                @content;
            }
        }
    }
}

// -------------------------------------------------------
// Responsive type
// Usage: @include fluid(font-size, 768px, 1310px, 14px, 18px);
// -------------------------------------------------------

@mixin fluid($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(
                #{$min-value} +
                    #{strip-unit($max-value - $min-value)} *
                    (100vw - #{$min-vw}) /
                    #{strip-unit($max-vw - $min-vw)}
            );
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
