#artistContent {

    p {
        margin-bottom: 40px;
    }

    .wp-block-image img {
        max-width: 100%;
        height: auto;
    }

    // ------------------------------
    // Captioned text styles
    // ------------------------------

    .wp-caption > p {
        margin: 0;
    }
    .wp-caption[class*="align"] img {
        margin-bottom: 0;
    }

    .wp-caption-text {
        font-size: 1.4rem;
        border-bottom: 1px solid inherit;

        strong {
            margin-right: 10px;
            color: inherit;
        }
    }

    // ------------------------------
    // Alignment styles
    // ------------------------------

    .aligncenter,
    .alignnone {
        clear: both;
        width: auto;
        height: auto;
        max-width: 100%;
        clear: both;
    }

    .aligncenter {
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        .wp-caption-text {
            margin-left: auto;
            margin-right: auto;
        }
    }

    img.aligncenter {
        margin-left: auto !important;
        margin-right: auto !important;
    }


    // ------------------------------
    // Standard blockquote
    // ------------------------------

    blockquote.wp-block-quote {
        margin-left: 0;
        margin-right: 0;
        padding-left: 30px;
        border-left: 4px solid;
        border-color: inherit;
    }
    .wp-block-pullquote {
        border-width: 4px 0px;
        border-style: solid;
        border-color: inherit;
        blockquote {
            margin-top: 0px;
            margin-bottom: 0px;
            p:first-child {
                margin-top: 0px;
            }
        }
    }


    // ------------------------------
    // Iframe video
    // ------------------------------

    .iframe-wrapper {
        position: relative;
        padding-bottom: percentage(9 / 16);
        //margin-top: $spacing/2;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // ------------------------------
    // Breakpoint styles
    // ------------------------------

    @include breakpoint(769px, 0) {
        .alignleft,
        .alignright {
            max-width: 350px;
            width: auto;
            height: auto;
            //margin-top: $spacing/4;
            //margin-bottom: $spacing/4;
        }
        .alignleft {
            float: left;
            //margin-right: $spacing;
        }
        .alignright {
            float: right;
            //margin-left: $spacing;
        }
    }
    @include breakpoint(768px, 1490px) {
        .alignleft,
        .alignright {
            width: 50%;
        }
    }
    @include breakpoint(0, 767px) {
        .alignleft,
        .alignright,
        .aligncenter,
        .alignnone {
            text-align: center;
            margin: 30px auto;

            img,
            .wp-caption-text {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    @include breakpoint(0, 479px) {
        .wp-video {
            max-width: 100%;
            width: auto;
            height: auto;
        }
        .alignleft,
        .alignright,
        .aligncenter,
        .alignnone {
            width: 100%;
            margin: 30px 0;
            max-width: none !important;
            text-align: left;

            .wp-caption-text {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
