body{
    overflow-x: hidden;
}
.container {
    margin: 0px auto;
    position: relative;
}
#header,
#footer {
    color: #27282d;
    .container {
        max-width: 1390px;
        padding: 0px 30px;
    }
    .grid {
        display: flex;
        flex-direction: row;
        margin-left: -40px;
        align-items: center;
        justify-content: space-between;

        > .grid-item {
            padding-left: 40px;
        }
        &.top {
            align-content: flex-start;
        }
        @include breakpoint(0, 1000px) {
            margin-left: -20px;
            > .grid-item {
                flex-grow: 1;
                padding-left: 20px;
            }
        }
    }
    a {
        text-decoration: none;
    }
    h2, h3, h4, .title {
        font-family: solano gothic retro mvb sb cap, Helvetica, Arial, sans-serif;
    }
    .title {
        svg {
            max-width: none;
            width: auto;
            height: 18px;
            max-height: 18px;
        }
    }
    .return {
        min-width: 132px;
        svg {
            transform: rotate(180deg);
        }
    }
    button {
        background: #ea1e52;
        color: #fff;
        border: 0px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: solano gothic retro mvb sb cap, Helvetica, Arial, sans-serif;
    }
    .hide-tablet {
        @include breakpoint(0, 1000px) {
            display: none;
        }
    }
    .hide-mobile {
        @include breakpoint(0, 767px) {
            display: none;
        }
    }
}