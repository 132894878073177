div.custom-bg-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    z-index: -1;
    width: 100vw;

    &.fixed {
        position: fixed;
    }

    &.parallax {
        position: fixed;
        top: -20vw;
    }

    div#custom_bg {
        width: 100%;
        height: 100%;
    }
}