.notification-outdated-browser,
.notification-no-js {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    text-align: center;
    line-height: 1;
    padding: 20px 30px;
    background-color: $brand-primary;
    color: white;
    font-weight: $fw-medium;
    font-size: 1.8rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .notification-outdated-browser {
        display: block;
    }
}
