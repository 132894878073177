header#header {
    background: #fff;
    padding: 24px 0px;
    margin-bottom: 60px;
    h2, h3, h4, .title {
        font-size: 18px;
        margin: 0px;
    }
    a {
        color: #27282d;
        > span {
            line-height: 18px;
            display: inline-block;
            padding-left: 10px;
            @include breakpoint(0, 780px) {
                padding-left: 6px;
            }
        }
        * {
            vertical-align: middle;
        }
    }
    .grid {
        justify-content: flex-start;
        .grid-item {
            &:last-child {
                text-align: right;
                flex-grow: 1;
            }
            &.join {
                span {
                    padding-right: 14px;
                }
                svg {

                }
            }
        }
    }
    @include breakpoint(0, 780px) {
        .grid {
            .grid-item {
                flex-grow: 0;
                .logo {
                    svg {
                        width: 138px;
                        height: 40px;
                    }
                }
                &.return {
                    flex-grow: 1;
                }
                &.login {
                    text-align: center;
                    flex-grow: 0;
                    span:not(.hide-mobile):not(.hide-tablet) {
                        display: block;
                        padding-left: 0px;
                    }
                }

                &.join {
                    display: none;
                }
            }
        }
    }
}