#artistContent {

    // ------------------------------
    // Full width elements, Container padding
    // ------------------------------
    > *:not(.is-style-hero):not(.is-style-full-width):not(.has-background) {
        padding: 0px 30px;
    }
    > .has-background:not(.is-style-hero):not(.is-style-full-width) {
        margin-left: 30px;
        margin-right: 30px;
        max-width: calc(100% - 60px);
    }
    .is-style-hero {
        margin-top: -60px;
    }
    .is-style-hero > *:not(img):not(video),
    .is-style-full-width > *:not(img):not(video) {
        margin-right: auto;
        margin-left: auto;
    }
    .is-style-hero,
    .is-style-full-width {
        max-width: 100vw;
        width: 100vw;
        padding-right: 30px;
        padding-left: 30px;
    }
    figure.is-style-full-width {
        padding: 0px;
        margin: 0px;
        img {
            width: 100vw;
            height: auto;
            display: block;
        }
    }
    .is-style-full-width:last-child {
        margin-bottom: -60px;
    }

    // Block media & text
    .wp-block-media-text__media img {
        height: auto;
        margin-bottom: 0px;
    }
}

// Block embed
.wp-block-embed {
    margin: 0px;
    .wp-block-embed__wrapper {
        @include ratio(#{percentage(9 / 16)});
        position: relative;
        iframe {
            display: block !important;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-position: center center;
            object-fit: cover;
            border: none;
        }
    }
}