.block--slider {
    position: relative;
    .slick-list {
        margin: 0px 30px;
        .slick-track {
            display: flex;
            align-items: center;
            .slick-slide {
                img {
                    max-width: 100%;
                    height: auto;
                    margin: 0px auto;
                    display: block;
                }
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: calc(50% - 10px);
        background: none;
        border: 0px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .slick-prev {
        left: 20px;
    }

    .slick-next {
        right: 20px;
    }
    &.chevron {
        .slick-arrow {
            border-style: solid;
            border-color: inherit;
            transform: rotate(45deg);
            overflow: hidden;
            text-indent: 30px;
        }

        .slick-prev {
            left: 10px;
            border-top: 2px;
            border-right: 2px;
        }

        .slick-next {
            right: 10px;
            border-bottom: 2px;
            border-left: 2px;
        }
    }
    .slick-dots {
        margin: 0px auto;
        padding: 10px;
        li {
            margin-bottom: 0px;
            padding: 0px;
            button {
                text-indent: 20px;
                overflow: hidden;
                border-color: inherit;
            }
        }
    }
    .slick-control {
        bottom: 5px;
    }
    @include breakpoint(0, 1024px) {
        .slick-dots {
            padding: 10px 0px;
        }
        .slick-control {
            &--prev {
                left: -10px;
            }
        }
    }
}