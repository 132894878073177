/*! Humaan DNA v5 */

// Vendor
@import "~modern-normalize/modern-normalize";

// Utilities
@import "_utilities/variables";
@import "_utilities/mixins";
@import "_utilities/helpers";
@import "_utilities/type";
@import "_utilities/fonts";
@import "_utilities/notification"; // Includes no js and ie11 outdated browser notification
@import "_utilities/gutenberg";

// Layouts
@import "_layout/base";
@import "_layout/background";
@import "_layout/cms-content";
@import "_layout/header-main";
@import "_layout/footer-main";


// Blocks
@import "_blocks/banner--video";
@import "_blocks/block--slider";


// Vendor
@import "_vendor/slick";