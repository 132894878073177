.block--banner-video {
    position: relative;
    padding: 0px;
    overflow: hidden;
    &.align-left {
        text-align: left;
    }
    &.align-center {
        text-align: center;
    }
    &.align-right {
        text-align: right;
    }
    .banner__inner {
        position: relative;
        z-index: 2;
    }
    .bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-position: center center;
        object-fit: cover;
    }
}
