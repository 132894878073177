// Type Scale
// --------------------------------------------------

html {
	text-size-adjust: 100%;
}

html, body {
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
}


// Links
// --------------------------------------------------

a {
	outline: 0;
	transition: color $timing linear;
	color: $brand-primary;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.focus-visible {
	outline: 1px dotted #212121;
	outline: 5px auto -webkit-focus-ring-color;
}