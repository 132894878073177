footer#footer {
    margin-top: 60px;
    padding: 65px 0 0;
    background: #27282d;
    color: #fff;
    position: relative;
    z-index: 100;
    font-size: 16px;
    font-family: vista-sans, "Helvetica Neue", Helvetica, Arial, sans-serif;

    svg {
        max-width: none;
        width: auto;
        height: 18px;
        max-height: 18px;
    }

    .container {
        .title {
            margin: 0 0 25px;
        }

        a {
            color: #fff;
            &:hover {
                color: #b8b8b8;
            }
        }

        &.footer-upper {
            padding-bottom: 60px;
            .title {
                color: #fff;
                font-size: 22px;
            }
            .grid {
                align-items: stretch;
                > .grid-item {
                    flex-grow: 1;
                    &:last-child {
                        background-position: left;
                        background-image: linear-gradient(#626367 33.33333%,rgba(255,255,255,0) 0%);
                        background-size: 2px 6px;
                        background-repeat: repeat-y;
                    }
                }
            }
        }

        .acknowledgement {
            background-position: top;
            background-image: linear-gradient(to right, #626367 33.33333%, rgba(255, 255, 255, 0) 0%);
            background-size: 6px 2px;
            background-repeat: repeat-x;
            padding: 60px 0;
        }
    }

    .footer-lower {
        background: #fff;
        padding: 40px 0;
        color: #27282d;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        font-family: solano gothic retro mvb sb cap, Helvetica, Arial, sans-serif;

        .grid {
            align-items: center;

            > .grid-item {
                display: flex;
                align-items: center;
                &.right {
                    flex-grow: 1;
                    justify-content: flex-end;
                    margin-top: 10px;
                }
            }
        }

        a {
            color: #27282d;
        }

        .logo,
        .site-credits {
            display: inline-block;
        }

        .logo {
            width: 255px;
            height: 75px;
            >svg {
                width: auto;
                height: 70px;
                max-height: 70px;
            }
        }

        .site-credits {
            width: 100%;
            max-width: 210px;
            margin-top: 10px;

            p {
                margin: 0;
            }

            a {
                border-bottom: 1px solid #d7d7d8;
                &:hover, &.on_press {
                    color: #b8b8b8;
                }
            }
        }

        .social-links {
            max-width: 470px;
            margin: 0px 40px 0 0;

            .title {
                margin: 0px 0px 20px 0px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                margin: -8px -8px 0;

                > li {
                    padding: 8px;
                }
            }
        }

        .sign-up {
            -webkit-text-fill-color: #27282d !important;
            width: 20vw;

            .title {
                margin: 0 0 10px;
            }

            form {
                margin: 0;
            }

            label.error {
                position: absolute;
                font-weight: 400;
                font-family: vista-sans, helvetica neue, Helvetica, Arial, sans-serif;
                font-size: 11px;
                right: 0;
                opacity: .6;
                bottom: -23px;
            }

            input {
                font-size: 16px;
                color: #27282d;
                height: 35px;
                border: 0;
                padding: 10px 30px 10px 0;
                border-bottom: 1px solid #ebebeb;
                transition: color 200ms ease, border 200ms ease;
                width: calc(100% - 65px);
                font-family: solano gothic retro mvb sb cap, Helvetica, Arial, sans-serif;

                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder {
                    color: #acacac;
                }

                &:focus {
                    box-shadow: none;
                    outline: none;
                    background: 0 0;
                    border-bottom: 1px solid #27282d;

                    &::-webkit-input-placeholder,
                    &:-moz-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder {
                        color: #27282d;
                    }
                }

                &.error {
                    border-color: #c11f25;
                }

                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 1000px #fff inset;
                }
            }

            button {
                background: 0 0;
                width: 30px;
                height: 35px;
                top: 0;
                -webkit-appearance: none;
                border: 0px;
                outline: 0px;

                &:hover:before {
                    color: #c11f25;
                }
            }
        }
        .subscribe-form-wrapper {
            width: 100%;
            .subscription-success {
                font-weight: 400;
                font-family: vista-sans, helvetica neue, Helvetica, Arial, sans-serif;
                text-transform: none;
            }
        }
    }
    @include breakpoint(0, 1000px) {
        .footer-upper {
            .grid {
                flex-wrap: wrap;
                .grid-item:first-child {
                    flex-basis: 100%;
                }
            }
        }
        .footer-lower {
            .logo {
                width: 210px;
                svg {
                    width: 100%;
                }
            }
            .sign-up {
                display: none;
            }
        }
    }
    @include breakpoint(0, 767px) {
        .container.footer-upper {
            .grid {
                >.grid-item {
                    flex-basis: 100%;
                    &:last-child {
                        background-image: linear-gradient(rgba(0,0,0,0) 33.33333%,rgba(0,0,0,0) 0%);
                    }
                    .title {
                        margin: 10px 0px 0px;
                    }
                    p {
                        margin-top: 4px;
                    }
                }
            }
        }
        .footer-lower {
            .grid {
                flex-wrap: wrap;
                >.grid-item {
                    &:first-child {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}
